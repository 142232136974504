import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CommentBarRce from '../comment-bar-rce';
import styles from './reply-mobile-form.scss';
import { REPLY } from '../../constants/form-types';

class ReplyMobileForm extends Component {
  render() {
    const {
      formInitialValues,
      onSubmit,
      onChange,
      formIdentifier,
      dividerColor,
      postId,
      parentCommentId,
    } = this.props;
    return (
      <div className={styles.container} data-hook="reply-mobile-form">
        <div className={styles.divider} style={{ borderColor: dividerColor }} />
        <CommentBarRce
          formInitialValues={formInitialValues}
          onSubmit={onSubmit}
          onChange={onChange}
          formName={formIdentifier}
          containerClass={styles.forumContainer}
          placeholderKey="text-editor.reply-placeholder"
          scrollIntoView
          postId={postId}
          parentCommentId={parentCommentId}
          type={REPLY}
        />
      </div>
    );
  }
}

ReplyMobileForm.propTypes = {
  formInitialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  change: PropTypes.func,
  formIdentifier: PropTypes.string,
  dividerColor: PropTypes.string,
  postId: PropTypes.string,
  parentCommentId: PropTypes.string,
};

export default ReplyMobileForm;
