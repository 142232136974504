import { flowRight } from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import NewContentIndicator from '../new-content-indicator';
import { PinIcon } from '../icons/pin-icon';
import MoreButtonUniversalPost from '../more-button-universal/more-button-universal-post';
import CardHeaderLeftSide from './card-header-left-side';
import styles from './post-list-card-header.scss';

class PostListCardHeader extends Component {
  render() {
    const { post, origin, t } = this.props;
    const owner = post.owner;
    const date = post.createdDate;

    return (
      <div className={styles.header}>
        <CardHeaderLeftSide user={owner} date={date} />

        <div className={styles.headerRightSide}>
          <NewContentIndicator
            className={styles.newIndicator}
            hideNumber
            hideDot
            count={post.isSeen === false && 1}
          />
          {post.isPinned && (
            <PinIcon
              className={classNames('icon-secondary-fill', styles.pinnedIcon)}
              aria-label={t('post-list-item.pinned-post')}
            />
          )}

          <MoreButtonUniversalPost
            post={post}
            origin={origin}
            className={styles.moreButton}
            isMobile
          />
        </div>
      </div>
    );
  }
}

PostListCardHeader.propTypes = {
  post: PropTypes.object.isRequired,
  origin: PropTypes.string.isRequired,
};

export default flowRight(withTranslate)(PostListCardHeader);
