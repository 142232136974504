import PropTypes from 'prop-types';
import { map } from 'lodash';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { getIsViewsCountEnabled } from '../../selectors/app-settings-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { isQuestion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './post-stats.scss';
import CounterNumber from '../counter-number';

export const STAT_TYPES = {
  VIEWS: 'views',
  COMMENTS: 'comments',
  REPLIES: 'replies',
};
function PostStats({
  viewCount,
  commentCount,
  replyCount,
  isViewsCountEnabled,
  showViewCount,
  postType,
  customRenderOrder,
  postId,
  t,
}) {
  const isQuestionType = isQuestion(postType);

  const renderReplies = () => {
    return (
      isQuestionType &&
      typeof replyCount === 'number' && (
        <span className={classNames(styles.replyCount)} data-hook="post-stats__reply-count">
          {t('post-stats.reply-count', { count: replyCount })}
        </span>
      )
    );
  };

  const renderViews = () => {
    return isViewsCountEnabled ? (
      <span className={classNames(styles.viewCount)} data-hook="post-stats__view-count">
        {showViewCount && (
          <CounterNumber
            tKey="post-stats.view-count"
            initialValue={viewCount}
            entityId={postId}
            viewCount
          />
        )}
      </span>
    ) : null;
  };

  const renderComments = () => {
    return isQuestionType ? (
      <span className={classNames(styles.commentCount)} data-hook="post-stats__comment-count">
        <CounterNumber
          tKey="post-stats.answer-count"
          initialValue={commentCount}
          entityId={postId}
          commentsOnly
        />
      </span>
    ) : (
      <span className={classNames(styles.commentCount)} data-hook="post-stats__comment-count">
        <CounterNumber
          tKey="post-stats.comment-count"
          initialValue={commentCount + (replyCount || 0)}
          entityId={postId}
          totalComments
        />
      </span>
    );
  };

  const renderStats = () => {
    const renderTypeMap = {
      [STAT_TYPES.VIEWS]: renderViews,
      [STAT_TYPES.COMMENTS]: renderComments,
      [STAT_TYPES.REPLIES]: renderReplies,
    };

    if (customRenderOrder && customRenderOrder.length === 3) {
      return map(customRenderOrder, type => renderTypeMap[type]());
    } else {
      return map(renderTypeMap, render => render());
    }
  };

  return <div className={classNames(styles.counter, 'post-stats')}>{renderStats()}</div>;
}

PostStats.propTypes = {
  commentCount: PropTypes.number.isRequired,
  replyCount: PropTypes.number.isRequired,
  viewCount: PropTypes.number.isRequired,
  t: PropTypes.func,
  isViewsCountEnabled: PropTypes.bool,
  showViewCount: PropTypes.bool,
  postType: PropTypes.string,
  postId: PropTypes.string,
  customRenderOrder: PropTypes.array,
};

PostStats.defaultProps = {
  commentCount: 0,
  viewCount: 0,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  showViewCount: !isCacheableRendering(state),
});

export default connect(mapRuntimeToProps, [REDUCERS.BASIC_PARAMS])(withTranslate(PostStats));
