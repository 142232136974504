import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import PageCover from '../page-cover';
import classNames from 'classnames';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { CurrencySignIcon } from '../icons/currency-sign-icon';
import Link from '../link/internal-link';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import { getIsShowSubcategoriesEnabled } from '../../selectors/app-settings-selectors';
import { getSubcategories } from '../../../common/selectors/categories-selectors';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withAuth from '../../hoc/with-auth';
import styles from './category-list-item-desktop.scss';
import FollowButton from '../follow-button';
import withPermissions from '../../hoc/with-permissions';
import SubcategoryListItemDesktop from './subcategory-list-item-desktop';
import { HorizontalSeparator } from '../separator';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';
import FollowNotification from '../follow-notification/follow-notification';
import { ViewCountIcon } from '../icons/view-count-icon';
import { CommentIconSmall } from '../icons/comment-icon-small';
import NewContentIndicator from '../new-content-indicator';
import { SORT_BY_NEWEST } from '@wix/communities-forum-client-commons/dist/src/constants/sorting';
import CounterNumber from '../counter-number';

const COVER_WIDTH = 460;
const COVER_HEIGHT = 254;

export const CategoryListItemDesktop = props => {
  const {
    category,
    t,
    borderWidth,
    titleFontClassName,
    contentFontClassName,
    isViewsCountEnabled,
    fetchCategoryPosts,
    resetPostTypeFilter,
    isShowSubcategoriesEnabled,
    subcategories,
    can,
    showViewCount,
    hasCategoryPP,
    setPostsPageSorting,
  } = props;
  const shouldShowMoreButton = can('edit', 'category', category);
  const hasCover = category.coverType === COVER_TYPE_IMAGE;

  const className = classNames(
    styles.container,
    'forum-card-background-color',
    'forum-card-border-color',
    'category-list-item',
    {
      [styles.hasCover]: hasCover,
    },
  );
  const categoryLink = `/${category.slug}`;

  const handlePostFetch = sort => () => {
    sort && setPostsPageSorting(sort, { dontReportBI: true });
    resetPostTypeFilter();
    fetchCategoryPosts({ categoryId: category._id, page: 1, sort });
  };

  const linkToPosts = (children, dataHook, sort) => (
    <Link
      className={classNames('forum-text-color', 'forum-link-hover-color', styles.postLink)}
      to={categoryLink}
      onMouseDown={handlePostFetch(sort)}
      data-hook={dataHook}
      aria-labelledby={`cl-${category._id}`}
    >
      {children}
    </Link>
  );
  const moreButton = (
    <MoreButton size={MoreButton.SIZE_SMALL}>
      <CategoryActions hideFollowButton category={category} />
    </MoreButton>
  );
  const categoryActions = (
    <div className={styles.actionsContainer}>
      <FollowButton
        category={category}
        className={shouldShowMoreButton ? styles.followButton : ''}
        data-hook="categories-list-item__follow-button"
      >
        <FollowNotification isFollowing={category.isSubscribed} />
      </FollowButton>
      {shouldShowMoreButton && moreButton}
    </div>
  );
  let categoryBadge = null;
  if (isPrivate(category)) {
    const Icon = hasCategoryPP ? CurrencySignIcon : LockEmptyIcon;
    categoryBadge = (
      <span className={styles.categoryBadgeWrapper}>
        <Icon className={'forum-icon-fill'} />
      </span>
    );
  }
  const titleText = (
    <h2
      name={category.label}
      className={classNames(styles.title)}
      data-hook="category-list-item__title"
      id={`cl-${category._id}`}
    >
      {category.label}
    </h2>
  );
  const title = linkToPosts(titleText);
  const pageCover = (
    <PageCover
      cover={category.cover}
      coverType={category.coverType}
      backgroundColor={category.backgroundColor}
      width={COVER_WIDTH}
      height={COVER_HEIGHT}
    />
  );
  return (
    <div
      className={classNames(className, 'forum-text-color')}
      style={{ borderWidth }}
      data-hook="category-list-item"
    >
      <div className={styles.cover}>{linkToPosts(pageCover)}</div>
      <div className={styles.contentWrapper}>
        <div className={classNames(styles.content, contentFontClassName)}>
          <div className={styles.titleWrapper}>
            <div className={classNames(titleFontClassName, styles.titleWithIcon)}>
              {title}
              {categoryBadge}
            </div>
            {categoryActions}
          </div>
          <div className={styles.statistics} data-hook="category-list-item__statistics">
            {isViewsCountEnabled && (
              <div
                className={styles.numberElementWithIcon}
                data-hook="category-list-item__total-views"
              >
                <ViewCountIcon
                  title={t('subcategory-list-item.views')}
                  className="forum-icon-fill"
                />
                <span className={classNames(styles.numberElementText)}>
                  {showViewCount ? (
                    <CounterNumber entityId={category._id} viewCount />
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </span>
              </div>
            )}

            <div
              className={classNames(styles.numberElementWithIcon, styles.postsNumber)}
              data-hook="category-list-item__total-posts"
            >
              <CommentIconSmall
                title={t('subcategory-list-item.posts', 'subcategories-list-item__total-posts')}
                className="forum-icon-fill"
              />
              <span className={classNames(styles.numberElementText)}>
                <CounterNumber
                  initialValue={category.totalPosts}
                  entityId={category._id}
                  totalPosts
                />
              </span>
            </div>
            {linkToPosts(
              <NewContentIndicator count={category.unseenPostsCount} />,
              null,
              SORT_BY_NEWEST,
            )}
          </div>
          {category.description && (
            <p className={styles.description} data-hook="category-list-item__description">
              {category.description}
            </p>
          )}
          {isShowSubcategoriesEnabled && subcategories.length > 0 ? (
            <div className={styles.subcategoriesContainer}>
              {subcategories.map(subcategory => (
                <div key={subcategory._id}>
                  <HorizontalSeparator />
                  <SubcategoryListItemDesktop
                    category={subcategory}
                    isViewsCountEnabled={isViewsCountEnabled}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

CategoryListItemDesktop.propTypes = {
  category: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  showViewCount: PropTypes.bool.isRequired,
  hasCategoryPP: PropTypes.bool,
  fetchCategoryPosts: PropTypes.func.isRequired,
  setPostsPageSorting: PropTypes.func.isRequired,
  resetPostTypeFilter: PropTypes.func,
  isShowSubcategoriesEnabled: PropTypes.bool,
  subcategories: PropTypes.arrayOf(PropTypes.object),
  can: PropTypes.func,
};

const mapRuntimeToProps = (state, { category }, actions, host) => ({
  fetchCategoryPosts: actions.fetchCategoryPosts,
  resetPostTypeFilter: actions.resetPostTypeFilter,
  setPostsPageSorting: actions.setPostsPageSorting,
  isShowSubcategoriesEnabled: getIsShowSubcategoriesEnabled(state, host.style),
  subcategories: getSubcategories(state, category._id),
  showViewCount: !isCacheableRendering(state),
  hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withCardBorderWidth,
  withFontClassName,
  withTranslate,
  withPermissions,
)(CategoryListItemDesktop);
