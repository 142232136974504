import { connect } from '../../common/components/runtime-context';
import { buildCustomizedUrl } from '../services/build-customized-url';

const mapRuntimeToProps = state => ({
  buildCustomizedUrl: (path, isFullUrl) => buildCustomizedUrl(state, path, isFullUrl)
});

export default function withCustomUrlBuilder(WrappedComponent) {
  return connect(mapRuntimeToProps)(WrappedComponent);
}
