import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import SearchButton from '../search-button';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withLayoutType from '../../hoc/with-layout-type';
import withPermissions from '../../hoc/with-permissions';
import styles from './header-buttons.scss';
import { doesUserHavePermissionsToSeeCategoryActions } from '../../containers/category-actions/category-actions';
import {
  MODAL_TYPE_FORUM_ACTIONS,
  MODAL_TYPE_CATEGORY_ACTIONS,
  MODAL_TYPE_POST_ACTIONS,
} from '../modals/action-sheet/action-sheet-modal-types';
import MoreButtonMobile from '../more-button-mobile/more-button-mobile';
import MoreIcon from '../icons/more-icon';
import { getPostBySlug } from '../../selectors/post-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

export const HeaderButtons = ({ isInPost, category = {}, post, can }) => (
  <div className={classNames(styles.container, 'header-buttons')}>
    <div className={styles.searchButtonSpacing} data-hook="search-button">
      <SearchButton />
    </div>
    {renderActions({ category, can, isInPost, post })}
  </div>
);

function renderActions({ category, can, isInPost, post }) {
  let modalType;

  if (isInPost) {
    modalType = MODAL_TYPE_POST_ACTIONS;
  } else if (category._id && doesUserHavePermissionsToSeeCategoryActions(can, category)) {
    modalType = MODAL_TYPE_CATEGORY_ACTIONS;
  } else {
    modalType = MODAL_TYPE_FORUM_ACTIONS;
  }

  if (!modalType) {
    return null;
  }

  return (
    <div className={classNames(styles.actions, 'forum-icon-fill')}>
      <MoreButtonMobile
        Icon={MoreIcon}
        type={modalType}
        category={category}
        post={post}
        showProfileActions
        showMarkPostsAsRead
      />
    </div>
  );
}

HeaderButtons.propTypes = {
  currentUser: PropTypes.object,
  t: PropTypes.func,
  params: PropTypes.object.isRequired,
  category: PropTypes.object,
  post: PropTypes.object,
  canRender: PropTypes.func.isRequired,
  isInPost: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isInPost: ownProps.params.postSlug !== undefined,
  post: getPostBySlug(state, ownProps.params.postSlug),
  category: ownProps.params.categorySlug
    ? getCategoryBySlug(state, ownProps.params.categorySlug)
    : {},
});

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.POSTS, REDUCERS.CATEGORIES]),
  withTranslate,
  withLayoutType,
  withPermissions,
)(HeaderButtons);
