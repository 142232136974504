import { isNil, get, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { CREATE_POST } from '../../constants/interactions';
import {
  getCategoryIdBySlug,
  getAllCategoryCount,
  getAllCategories,
} from '../../../common/selectors/categories-selectors';
import PostPageLayout from '../../components/post-page-layout';
import PostForm from '../../components/post-form';
import { MODAL_TYPE_CHOOSE_CATEGORY } from '../../components/modals/choose-category-modal/choose-category-modal-type';
import { MODAL_TYPE_DISCARD_POST } from '../../components/modals/discard-post-modal/discard-post-modal-type';
import {
  ROUTE_CREATE_QUESTION,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_CREATE_POST,
} from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import {
  QUESTION,
  DISCUSSION,
} from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { getRouteParams, getRoute } from '../../../common/router/router-selectors';
import postFormSettings from '../../services/post-form-settings';
import { withFastForm } from '../../../common/components/fast-form';
import {
  enableBrowserRefreshPrompt,
  disableBrowserRefreshPrompt,
} from '../../../common/services/browser-refresh-prompt-service';
import styles from './post-create-page.scss';

export class PostCreatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        categoryId: props.categoryId,
        postType: props.postType,
      },
    };
  }

  componentDidMount() {
    enableBrowserRefreshPrompt();
  }

  componentWillUnmount() {
    disableBrowserRefreshPrompt();
  }

  isInCategory = () => !isNil(this.props.categoryId);

  handleSubmit = ({ values: post }) => {
    this.props.interactionStarted(CREATE_POST);
    this.props.createPostAndNavigateToItPromisified(post).then(response => {
      if (response && !response.isSuccessful) {
        this.props.fastForm.stopSubmit();
      }
    });
  };

  handleSubmitButtonClick = submit => {
    const { categoryId, openModal, fastForm } = this.props;

    if (isNil(categoryId) && isNil(fastForm.values.categoryId)) {
      openModal(MODAL_TYPE_CHOOSE_CATEGORY).then(r => {
        r &&
          this.props.fastForm.changeValue('categoryId')(r.categoryId, () => {
            submit();
          });
      });
    } else {
      submit();
    }
  };

  handleCancel = isModified => {
    const { categorySlug } = this.props.params;
    const { postType, fastForm } = this.props;
    if (isModified) {
      return this.props
        .openModal(MODAL_TYPE_DISCARD_POST, {
          categorySlug: this.isInCategory() ? categorySlug : null,
          postType,
        })
        .then(r => {
          if (r) {
            fastForm.resetForm();
          }
        });
    } else {
      return this.props.goBack();
    }
  };

  render() {
    return (
      <PostPageLayout className={classNames(styles.postCreatePage, 'forum-card-background-color')}>
        <PostForm
          onSubmit={this.handleSubmit}
          onSubmitButtonClick={this.handleSubmitButtonClick}
          onCancel={this.handleCancel}
          formInitialValues={this.state.initialValues}
          showCategorySelect={!this.isInCategory()}
          postType={this.props.postType}
          categoryId={this.props.categoryId}
        />
      </PostPageLayout>
    );
  }
}

PostCreatePage.propTypes = {
  createPostAndNavigateToItPromisified: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  submit: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
  post: PropTypes.object,
  params: PropTypes.object,
  interactionStarted: PropTypes.func,
  showMessage: PropTypes.func,
  fastForm: PropTypes.object,
  route: PropTypes.string,
  postType: PropTypes.string,
};

const mapDispatchToProps = (state, ownProps, actions) => {
  const categoryCount = getAllCategoryCount(state);
  const categories = getAllCategories(state);
  const params = getRouteParams(state);
  let categoryId = getCategoryIdBySlug(state, params.categorySlug);

  if (isNil(categoryId) && categoryCount === 1) {
    categoryId = get(categories[0], '_id', null);
  }

  const route = getRoute(state);
  const isQuestionRoute = [ROUTE_CREATE_QUESTION, ROUTE_CATEGORY_CREATE_QUESTION].includes(route);

  return {
    params,
    route,
    categoryId,
    createPostAndNavigateToItPromisified: actions.createPostAndNavigateToItPromisified,
    goBack: () => actions.goBack(),
    openModal: actions.openModal,
    showMessage: actions.showMessage,
    interactionStarted: actions.interactionStarted,
    isDiscussionRoute: getRoute(state) === ROUTE_CREATE_POST,
    postType: isQuestionRoute ? QUESTION : DISCUSSION,
  };
};

export default flowRight(
  connect(mapDispatchToProps, [REDUCERS.ROUTER, REDUCERS.CATEGORIES]),
  withFastForm(postFormSettings, { skipInitialize: true }),
)(PostCreatePage);
