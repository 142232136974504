import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import { sortingToTranslations } from '../../services/sorting-to-translations';
import styles from './filters-bar-mobile.scss';
import withFontClassName from '../../hoc/with-font-class-name';

const FiltersBarMobile = ({ contentFontClassName, currentSort, rightSideComponent, t }) => (
  <div className={styles.container}>
    <span className={classNames(styles.filterText, contentFontClassName, 'forum-text-color')}>
      {t(sortingToTranslations[currentSort])}
    </span>
    {rightSideComponent}
  </div>
);

FiltersBarMobile.propTypes = {
  currentSort: PropTypes.string,
  contentFontClassName: PropTypes.string,
  t: PropTypes.func,
};

export default flowRight(withTranslate, withFontClassName)(FiltersBarMobile);
