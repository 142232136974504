import moment from 'moment';
import './moment-locales';
import { MOMENT_LOCALES, DATE_FORMATS } from '../constants/time';
import { compactNumber } from './format-number';

const I18N_NESTING_KEY_SEPARATOR = '$';
const getDateLocale = lng => MOMENT_LOCALES[lng] || MOMENT_LOCALES['en'];
const getDateFormat = lng => DATE_FORMATS[lng] || DATE_FORMATS['en'];

export default function createI18nConfig() {
  return {
    keySeparator: I18N_NESTING_KEY_SEPARATOR,
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        switch (format) {
          case 'uppercase':
            return value.toUpperCase();
          case 'number':
            return compactNumber(lng)(value);
          case 'timeRelative':
            return moment(value)
              .locale(getDateLocale(lng))
              .fromNow();
          case 'date':
            return moment(value)
              .locale(getDateLocale(lng))
              .format(getDateFormat(lng));
          case 'fullDate':
            return moment(value)
              .locale(getDateLocale(lng))
              .format('ll');
          default:
            return value;
        }
      },
    },
  };
}
