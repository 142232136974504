import { flowRight, isString, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { WixRicosViewer } from '@wix/ricos-viewer';
import { getIsSocialSharingLinksEnabled } from '../../selectors/app-settings-selectors';
import { getLocation } from '../../../common/store/location/location-selectors';
import {
  isSeo,
  isEditor,
  getLanguage,
  isSSR,
  isPreview,
} from '../../../common/store/basic-params/basic-params-selectors';
import { PLUGINS } from '@wix/communities-forum-client-commons/dist/src/constants/plugins-constants';
import getImageUrl from '@wix/communities-forum-client-commons/dist/src/services/get-image-url';
import { isIos } from '../../../common/services/detect-platform';
import getOuterUrl from '../../services/get-outer-url';
import withDeviceType from '../../hoc/with-device-type';
import withFontClassName from '../../hoc/with-font-class-name';
import {
  createHashtagHref,
  createHashtagPath,
  MAX_PINTEREST_IMAGE_SIZE,
  HTML_IFRAME_SRC,
} from '../rich-content-editor/utils';
import { isMemberAreaInstalled } from '../../../common/store/communities-context/communities-context-selectors';
import {
  viewerCustomStyleFn,
  styleSelectionPredicate,
  getTextColorSchema,
} from '../rich-content-editor/text-color-utils';
import { RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY } from '../messages/message-types';
import { mapConfigToPlugins } from './plugins';
import theme from '../rich-content-editor/theme';
import styles from './rich-content-viewer.scss';

class RichContentViewer extends Component {
  constructor(props) {
    super(props);
    this.initViewerProps();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isEditor ||
      nextProps.pageUrl !== this.props.pageUrl ||
      nextProps.initialState !== this.props.initialState ||
      nextProps.isSSR !== this.props.isSSR ||
      this.state !== nextState
    );
  }

  componentDidUpdate() {
    if (this.props.isEditor) {
      this.initTheme();
    }
  }

  getImagePluginConfig() {
    const { isSocialSharingLinksEnabled, isMobile } = this.props;
    const showPin = this.props.readOnly && isSocialSharingLinksEnabled;
    return {
      imageProps: showPin
        ? src => ({
            'data-pin-url': this.props.pageUrl,
            'data-pin-media': isString(src)
              ? src
              : getImageUrl(src, MAX_PINTEREST_IMAGE_SIZE, MAX_PINTEREST_IMAGE_SIZE),
          })
        : { 'data-pin-nopin': true },
      disableExpand: isMobile,
    };
  }

  getVideoPluginConfig() {
    return {
      // Function is invoked when rendering video which has relative URL.
      // You should take the pathname and form a full URL.
      getVideoUrl: src => `https://video.wixstatic.com/${src.pathname}`,
    };
  }

  onHashTagClick = event => {
    event.preventDefault();
    event.stopPropagation();
    const pathname = createHashtagPath(event.target.innerText);
    this.props.navigateWithinForum(pathname);
  };

  initViewerProps() {
    const {
      isMembersAreaInstalled,
      navigateToProfile,
      requestFileDownloadUrlPromisified,
      fileDownloadRequest,
      showMessage,
      style,
      origin,
      buttonClicked,
      sectionUrl,
    } = this.props;

    this.biSettings = {
      consumer: 'communities-forum-client',
      biCallbacks: {
        onViewerAction: (actionName, pluginId) => {
          switch (actionName) {
            case 'expand_image':
              return buttonClicked({ name: 'img_expand', origin });
          }
        },
      },
    };

    this.initTheme();

    const colorScheme = getTextColorSchema(style);

    this.config = {
      [PLUGINS.FILE_UPLOAD]: {
        resolveFileUrl: imgEntityData => {
          isIos() && showMessage(RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY);
          const getBiEventData = isSuccessful => ({
            fileId: imgEntityData.id,
            fileExtension: imgEntityData.type,
            mimeType: imgEntityData.mimeType,
            size: imgEntityData.size,
            isSuccessful,
            origin,
          });

          return requestFileDownloadUrlPromisified(imgEntityData.path)
            .then(data => {
              fileDownloadRequest(getBiEventData(true));
              return data.downloadUrl;
            })
            .catch(() => fileDownloadRequest(getBiEventData(false)));
        },
      },
      [PLUGINS.TEXT_COLOR]: {
        customStyleFn: viewerCustomStyleFn(colorScheme),
        styleSelectionPredicate: styleSelectionPredicate(colorScheme),
      },
      [PLUGINS.HTML]: {
        htmlIframeSrc: HTML_IFRAME_SRC,
      },
      [PLUGINS.IMAGE]: this.getImagePluginConfig(),
      [PLUGINS.VIDEO]: this.getVideoPluginConfig(),
      [PLUGINS.MENTIONS]: {
        onMentionClick: isMembersAreaInstalled
          ? mention => navigateToProfile({ memberId: mention.id, memberSlug: mention.slug })
          : null,
        getMentionLink: noop,
      },
      [PLUGINS.HASHTAG]: {
        onClick: this.onHashTagClick,
        createHref: createHashtagHref(sectionUrl),
      },
    };
    this.plugins = mapConfigToPlugins(this.config);
  }

  initTheme() {
    const {
      style,
      titleFontClassName,
      contentFontClassName,
      compact,
      isSeo,
      themeGetter,
      type,
      isMobile,
    } = this.props;

    this.theme = themeGetter({
      style,
      readOnly: true,
      compact,
      isViewer: true,
      isSeo,
      titleFontClassName,
      contentFontClassName,
      type,
      isMobile,
    });
  }

  render() {
    const { initialState, isMobile, locale, isSeo } = this.props;
    return (
      <div className={styles.container}>
        <WixRicosViewer
          content={initialState}
          plugins={this.plugins}
          isMobile={isMobile}
          locale={locale}
          cssOverride={this.theme}
          biSettings={this.biSettings}
          seoSettings={isSeo}
          linkSettings={{ anchorTarget: '_blank', relValue: 'nofollow ugc' }}
        />
      </div>
    );
  }
}

RichContentViewer.propTypes = {
  initialState: PropTypes.object,
  compact: PropTypes.bool,
  style: PropTypes.object.isRequired,
  navigateWithinForum: PropTypes.func.isRequired,
  pageUrl: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isSeo: PropTypes.bool,
  themeGetter: PropTypes.func,
  navigateToProfile: PropTypes.func,
  isMembersAreaInstalled: PropTypes.bool,
  isEditor: PropTypes.bool,
  isSocialSharingLinksEnabled: PropTypes.bool,
  sectionUrl: PropTypes.string,
  origin: PropTypes.string,
  requestFileDownloadUrlPromisified: PropTypes.func,
  showMessage: PropTypes.func,
  fileDownloadRequest: PropTypes.func,
  type: PropTypes.string,
  locale: PropTypes.string,
  isSSR: PropTypes.bool,
  isPreview: PropTypes.bool,
  buttonClicked: PropTypes.func,
};

RichContentViewer.defaultProps = {
  compact: false,
  themeGetter: theme,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const location = getLocation(state);
  const sectionUrl = location.sectionUrl;
  return {
    style: host.style,
    pageUrl: getOuterUrl(location.pathname, sectionUrl),
    isSeo: isSeo(state),
    isEditor: isEditor(state),
    isPreview: isPreview(state),
    isMembersAreaInstalled: isMemberAreaInstalled(state),
    isSocialSharingLinksEnabled: getIsSocialSharingLinksEnabled(state, host.style),
    sectionUrl,
    navigateWithinForum: actions.navigateWithinForum,
    navigateToProfile: actions.navigateToProfile,
    requestFileDownloadUrlPromisified: actions.requestFileDownloadUrlPromisified,
    showMessage: actions.showMessage,
    fileDownloadRequest: actions.fileDownloadRequest,
    locale: getLanguage(state),
    isSSR: isSSR(state),
    buttonClicked: actions.buttonClicked,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withDeviceType,
  withFontClassName,
)(RichContentViewer);
